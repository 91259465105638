import $ from 'jquery';

export default function mNav (trigger, target, targetActive) {
    $(trigger).on('click', function(e) {
        $(target).addClass(targetActive);
        e.stopPropagation();
        return false;
    });
    $(document).click(function() {
	     $(target).removeClass(targetActive);
	});
}
